import React,{useState} from 'react';
import  'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css'; 
import { toast } from 'react-toastify'
import request from '../../services/request';
toast.configure()
const ContactForm = React.memo(props => {
    const [firstName, setfirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    async function handleSubmit(e) {
        if(!email) {
          e.preventDefault()
          alert("Please enter email")
        }
        const response = await request.post('/contact', {firstName, lastName, email, message});
        if (response.data){ 
        setfirstName('');
        setLastName('');
        setEmail('');
        setMessage('');
        toast.success("Thank you for your message. We will contact you soon")}
        else 
        toast.error(response.data.message)
    
        //dispatch(contact({firstName, lastName, email, message}, e))
      }
    
    
    
return(
<>

            <div id="project-contact-form">
                <div class="row">
                    <div class="col-12">
                        <div id="success-project-contact-form" class="mx-0"></div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <input type="text" name="name" id="name" placeholder="First Name " value={firstName} onChange={(e) => setfirstName(e.target.value)} class="medium-input"/>
                    </div>
                    <div class="col-12 col-lg-6">
                        <input type="text" name="name" id="name" placeholder="Last Name " value={lastName} onChange={(e) => setLastName(e.target.value)} class="medium-input"/>
                    </div>
                    <div class="col-12 col-lg-12">
                        <input type="email" name="email" id="email" placeholder="E-mail *" value={email} onChange={(e) => setEmail(e.target.value)} class="medium-input"/>
                    </div>
                    <div class="col-12">
                        <textarea name="description" cols="40" rows="10" class="medium-input" placeholder="Message " value={message} onChange={(e) => setMessage(e.target.value)} aria-invalid="false" spellCheck="false"></textarea>
                    </div>
                    <div class="col-12">
                        <button id="project-contact-us-button" type="submit" class="btn btn-transparent-dark-gray btn-large margin-20px-top" onClick={(e) => handleSubmit(e)} >submit</button>
                    </div>
                </div>
            </div>

</>);
 })
export default ContactForm;