import React,{useState,useEffect} from 'react';
import  'bootstrap/dist/css/bootstrap.min.css';
import loadable from '@loadable/component'
import request from '../services/request';
import banner from '../components/images/banner-contact.jpg'
// const Nav = loadable(() => import ('../components/Nav'))
// const Footer = loadable(() => import('../components/Footer'))
import Footer  from "../components/Footer";
import Nav from "../components/Nav";
import ContactForm from '../components/Forms/Contact';
// const ContactForm = loadable(() => import('../components/Forms/Contact'))

function Contact() {
    const [firstName, setfirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    async function handleSubmit(e) {
        if(!firstName || !lastName || !email || !message) {
          e.preventDefault()
          alert("Please enter all the fields")
        }
        const response = await request.post('/contact', {firstName, lastName, email, message});
        console.log(response, "res")
        //dispatch(contact({firstName, lastName, email, message}, e))
      }
    
    
    
return(
<>
<Nav/>
<section class="wow fadeIn min-height-400px cover-background top-space-verum" style={{backgroundImage: `url(${banner})`}}>
<div class="container">
    <div class="row">
        <div class="col-12 col-lg-8 wow fadeInLeft">
            <h3 class="inner-banner-title text-white">Contact, Support, Search</h3>
        </div> 
        <div class="col-12 col-lg-4 wow fadeInRight" data-wow-delay="0.2s"></div>
    </div>
</div>
</section>
<section class="padding-70px-tb wow fadeInUp">
<div class="container">
    <div class="row justify-content-center">
        <div class="col-12 col-lg-8 md-margin-50px-bottom sm-margin-30px-bottom">
            <h5 class="title-section-alt alt-font text-dark-gray letter-spacing-1">What can we help you with?</h5>
            <p>Fields marked with an * are required</p>
            <ContactForm/>
        </div>
        <div class="col-12 col-lg-4">
            <h5 class="title-section-alt alt-font text-dark-gray letter-spacing-1">Contact details</h5>
            <p><strong>Verum Software Tools B.V.</strong></p>
            <p>Automotive Campus 30<br/>5708 JZ Helmond<br/>Netherlands</p>
 
        </div>
    </div>                               
</div>
</section>
<Footer/>
</>) }
export default Contact
